export default {
    Questions: [
        {
            Section: 6,
            MaxLength: 0,
            Name: null,
            Id: 201,
            Options: [],
            ThirdPersonName: "Medeni durumu",
            ThirdPersonOptions: [
                {
                    Id: 1,
                    Name: "Bekar / Hiç Evlenmemiş",
                    QuestionId: 201,
                    ThirdPersonName: null,
                },
                {
                    Id: 2,
                    Name: "Evli",
                    QuestionId: 201,
                    ThirdPersonName: null,
                },
                {
                    Id: 3,
                    Name: "Dul",
                    QuestionId: 201,
                    ThirdPersonName: null,
                },
                {
                    Id: 4,
                    Name: "Boşanmış",
                    QuestionId: 201,
                    ThirdPersonName: null,
                },
                {
                    Id: 5,
                    Name: "Ayrı Yaşıyor",
                    QuestionId: 201,
                    ThirdPersonName: null,
                },
            ],
            AllowMultipleOptions: true,
            AllowText: false,
            InputType: 0,
            SelectedOptions: [],
        },
        {
            Section: 6,
            MaxLength: 0,
            Name: null,
            Id: 202,
            Options: [],
            ThirdPersonName: "İlişki Türü",
            ThirdPersonOptions: [
                {
                    Id: 2,
                    Name: "Arkadaşlık",
                    QuestionId: 202,
                    ThirdPersonName: null,
                },
                {
                    Id: 4,
                    Name: "E-Posta arkadaşlığı",
                    QuestionId: 202,
                    ThirdPersonName: null,
                },
                {
                    Id: 8,
                    Name: "Kısa süreli ilişki",
                    QuestionId: 202,
                    ThirdPersonName: null,
                },
                {
                    Id: 16,
                    Name: "Uzun süreli ilişki",
                    QuestionId: 202,
                    ThirdPersonName: null,
                },
                {
                    Id: 32,
                    Name: "Evlilik",
                    QuestionId: 202,
                    ThirdPersonName: null,
                },
            ],
            AllowMultipleOptions: true,
            AllowText: false,
            InputType: 0,
            SelectedOptions: [],
        },
        {
            Section: 6,
            MaxLength: 0,
            Name: null,
            Id: 203,
            Options: [],
            ThirdPersonName: "Mesleği",
            ThirdPersonOptions: [
                {
                    Id: 40,
                    Name: "Askeri personel",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 2,
                    Name: "Bankacılık - Finans",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 17,
                    Name: "Basın - Yayın",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 3,
                    Name: "Bilgisayar - Bilişim",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 4,
                    Name: "Çevirmen",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 5,
                    Name: "Danışmanlık",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 6,
                    Name: "Denizci",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 15,
                    Name: "Din Görevlisi",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 7,
                    Name: "Doktor - Hekim",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 8,
                    Name: "Eczacı",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 9,
                    Name: "Emekli",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 10,
                    Name: "Emniyet görevlisi",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 11,
                    Name: "Ev kadını",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 50,
                    Name: "Grafik Tasarım",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 12,
                    Name: "Halkla ilişkiler",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 13,
                    Name: "Hukukçu",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 14,
                    Name: "İlaç sektörü",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 16,
                    Name: "İnsan kaynakları",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 18,
                    Name: "İşsiz",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 19,
                    Name: "Kamu sektörü",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 20,
                    Name: "Manken - Fotomodel",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 21,
                    Name: "Memur",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 22,
                    Name: "Mimar",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 23,
                    Name: "Muhasebe",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 24,
                    Name: "Mühendis",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 25,
                    Name: "Müzik",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 26,
                    Name: "Otomotiv",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 27,
                    Name: "Öğrenci",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 28,
                    Name: "Akademisyen",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 29,
                    Name: "Öğretmen",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 30,
                    Name: "Politikacı",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 31,
                    Name: "Psikolog",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 32,
                    Name: "Reklam",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 33,
                    Name: "Sağlık hizmetleri",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 34,
                    Name: "Sanatçı",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 35,
                    Name: "Sanayici",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 36,
                    Name: "Satış - Pazarlama",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 37,
                    Name: "Serbest meslek",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 38,
                    Name: "Sigortacı",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 39,
                    Name: "Sporcu",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 49,
                    Name: "Tasarımcı",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 41,
                    Name: "Tekstil",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 42,
                    Name: "Ticaret",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 43,
                    Name: "Turizm",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 44,
                    Name: "Ulaşım - Taşımacılık",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 48,
                    Name: "Veteriner Hekim",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 46,
                    Name: "Yöneticilik",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
                {
                    Id: 47,
                    Name: "Diğer",
                    QuestionId: 203,
                    ThirdPersonName: null,
                },
            ],
            AllowMultipleOptions: true,
            AllowText: false,
            InputType: 0,
            SelectedOptions: [],
        },
        {
            Section: 6,
            MaxLength: 7,
            Name: null,
            Id: 200,
            Options: [],
            ThirdPersonName: "Burcu",
            ThirdPersonOptions: [
                {
                    Id: 1,
                    Name: "Akrep",
                    QuestionId: 200,
                    ThirdPersonName: null,
                },
                {
                    Id: 2,
                    Name: "Aslan",
                    QuestionId: 200,
                    ThirdPersonName: null,
                },
                {
                    Id: 3,
                    Name: "Balık",
                    QuestionId: 200,
                    ThirdPersonName: null,
                },
                {
                    Id: 4,
                    Name: "Başak",
                    QuestionId: 200,
                    ThirdPersonName: null,
                },
                {
                    Id: 5,
                    Name: "Boğa",
                    QuestionId: 200,
                    ThirdPersonName: null,
                },
                {
                    Id: 6,
                    Name: "İkizler",
                    QuestionId: 200,
                    ThirdPersonName: null,
                },
                {
                    Id: 7,
                    Name: "Koç",
                    QuestionId: 200,
                    ThirdPersonName: null,
                },
                {
                    Id: 8,
                    Name: "Kova",
                    QuestionId: 200,
                    ThirdPersonName: null,
                },
                {
                    Id: 9,
                    Name: "Oğlak",
                    QuestionId: 200,
                    ThirdPersonName: null,
                },
                {
                    Id: 10,
                    Name: "Terazi",
                    QuestionId: 200,
                    ThirdPersonName: null,
                },
                {
                    Id: 11,
                    Name: "Yay",
                    QuestionId: 200,
                    ThirdPersonName: null,
                },
                {
                    Id: 12,
                    Name: "Yengeç",
                    QuestionId: 200,
                    ThirdPersonName: null,
                },
            ],
            AllowMultipleOptions: true,
            AllowText: false,
            InputType: 0,
            SelectedOptions: [],
        },
        {
            Section: 6,
            MaxLength: 0,
            Name: "Aylık geliriniz",
            Id: 51,
            Options: [
                {
                    Id: 1,
                    Name: "22.000 TL altı",
                    QuestionId: 51,
                    ThirdPersonName: null,
                },
                {
                    Id: 2,
                    Name: "22.000-30.000 TL",
                    QuestionId: 51,
                    ThirdPersonName: null,
                },
                {
                    Id: 3,
                    Name: "30.000-40.000 TL",
                    QuestionId: 51,
                    ThirdPersonName: null,
                },
                {
                    Id: 4,
                    Name: "40.000-50.000 TL",
                    QuestionId: 51,
                    ThirdPersonName: null,
                },
                {
                    Id: 5,
                    Name: "50.000-70.000 TL",
                    QuestionId: 51,
                    ThirdPersonName: null,
                },
                {
                    Id: 6,
                    Name: "70.000-100.000 TL",
                    QuestionId: 51,
                    ThirdPersonName: null,
                },
                {
                    Id: 7,
                    Name: "100.000-150.000 TL",
                    QuestionId: 51,
                    ThirdPersonName: null,
                },
                {
                    Id: 8,
                    Name: "150.000-200.000 TL",
                    QuestionId: 51,
                    ThirdPersonName: null,
                },
                {
                    Id: 9,
                    Name: "200.000 TL üstü",
                    QuestionId: 51,
                    ThirdPersonName: null,
                },
            ],
            ThirdPersonName: "Aylık geliri",
            ThirdPersonOptions: [],
            AllowMultipleOptions: false,
            AllowText: false,
            InputType: 0,
            SelectedOptions: [],
        },
        {
            Section: 6,
            MaxLength: 0,
            Name: "Çocuk durumunuz",
            Id: 63,
            Options: [
                {
                    Id: 1,
                    Name: "Çocuksuzum",
                    QuestionId: 63,
                    ThirdPersonName: "Çocuksuz",
                },
                {
                    Id: 2,
                    Name: "Var, benimle yaşıyor",
                    QuestionId: 63,
                    ThirdPersonName: "Var, birlikte yaşıyor",
                },
                {
                    Id: 3,
                    Name: "Var, benimle yaşamıyor",
                    QuestionId: 63,
                    ThirdPersonName: "Var, birlikte yaşamıyor",
                },
                {
                    Id: 4,
                    Name: "Var, bazen benimle yaşıyor",
                    QuestionId: 63,
                    ThirdPersonName: "Var, bazen birlikte yaşıyor",
                },
            ],
            ThirdPersonName: "Çocuk durumu",
            ThirdPersonOptions: [],
            AllowMultipleOptions: false,
            AllowText: false,
            InputType: 0,
            SelectedOptions: [],
        },
    ],
};
