// #region Global Imports
import * as React from "react";
import { useSelector } from "react-redux";
import Link from "next/link";
// #endregion Global Imports

// #region Local Imports
import { IStore } from "@Interfaces";
import { ContentBox } from "../../styled";
import {
    Tint,
    ImageControls,
    ImageText,
    ImageButtons,
    DislikeButton,
    LikeButton,
    AnimatedEffectWrapper,
    DislikeGrow,
    LikeGrow,
    LikeShrink,
    DislikeShrink,
    IconWrapper,
} from "./styled";
import { IGame } from "./Game";
import { friendlyUsername, Preset, theme } from "@Definitions";
import { CloseIcon, Heart, Dislike } from "@Icons";
// #endregion Local Imports

const Game: React.FunctionComponent<IGame.IProps> = ({
    reduceList,
    like,
    dislike,
    showMatched,
    showNoQuota,
}): JSX.Element => {
    const [reaction, setReaction] = React.useState("");
    const user = useSelector((state: IStore) => state.dashboard.hotOrNot.list[0]);
    const { Username, Age, PhotoUrl, Id } = user;

    const likeStatus = useSelector((state: IStore) => state.dashboard.likeStatus);
    if (likeStatus.success !== undefined) {
        if (likeStatus.isMatch) {
            showMatched();
        } else if (likeStatus.reason === "QuotaNotAvailable") {
            showNoQuota();
        } else if (likeStatus.likedUserId) {
            reduceList();
        }
    }

    const dislikeResponse = useSelector((state: IStore) => state.dashboard.dislikeResponse);
    if (dislikeResponse !== undefined) {
        reduceList();
    }

    const onLike = () => {
        setReaction("like");
        setTimeout(() => {
            like(Id);
        }, 800);
    };

    const onDislike = () => {
        setReaction("dislike");
        setTimeout(() => {
            dislike(Id);
        }, 800);
    };

    const imageControls = () => {
        const name = friendlyUsername(Username, 14);

        return (
            <ImageControls>
                <ImageText>{`${name}, ${Age}`}</ImageText>
                <ImageButtons>
                    <DislikeButton onClick={onDislike}>
                        <CloseIcon width="12px" height="16px" color={theme.colors.white} />
                    </DislikeButton>
                    <LikeButton onClick={onLike}>
                        <Heart width="19px" height="17px" color={theme.colors.white}/>
                    </LikeButton>
                </ImageButtons>
            </ImageControls>
        );
    };

    const animatedLikeEffect = () => (
        <AnimatedEffectWrapper>
            <LikeGrow />
            <DislikeShrink>
                <CloseIcon width="12px" height="16px" />
            </DislikeShrink>
            <IconWrapper>
                <img src="icons/heart-border.svg" alt="like" />
            </IconWrapper>
        </AnimatedEffectWrapper>
    );

    const animatedDislikeEffect = () => (
        <AnimatedEffectWrapper>
            <DislikeGrow />
            <LikeShrink>
                <Heart width="19px" height="17px" />
            </LikeShrink>
            <IconWrapper>
                <Dislike width="46px" height="46px" />
            </IconWrapper>
        </AnimatedEffectWrapper>
    );

    const renderContent = () => {
        switch (reaction) {
            case "like":
                return animatedLikeEffect();
            case "dislike":
                return animatedDislikeEffect();
            default:
                return imageControls();
        }
    };

    return (
        <ContentBox>
            <Link href="/profile/[id]?source=hotornot" as={`/profile/${Id}`}>
                <a className="image-wrapper">
                    <img
                        src={`${PhotoUrl}${Preset.recentPhotos}`}
                        alt="user"
                        onLoad={() => {
                            setReaction("");
                        }}
                    />
                    <Tint />
                    {renderContent()}
                </a>
            </Link>
        </ContentBox>
    );
};

export { Game };
export { GameSkeleton } from "./Components";
