// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    position: relative;
    z-index: 1;
    background-image: ${({ theme }) => theme.gradients.grayHeader};
    box-shadow: 0 0px 20px 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    > img {
        height: 44px;
    }

    .with-text {
        width: 193px;
    }

    .without-text {
        width: 44px;
    }
`;
export const DFlex = styled.div`
    display: flex;
    align-items: center;
`;

export const IconsDiv = styled(DFlex)`
    
`;

export const IconButton = styled.div`
    display: grid;
    place-items: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: black;
    margin-right: 5px;

    &.apple-store {
        padding-bottom: 2px;
    }

    &.google-play {
        padding-left: 1px;
    }
`;