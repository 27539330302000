// #region Global Imports
import * as React from "react";
/* import { useSelector } from "react-redux"; */
// #endregion Global Imports

// #region Local Imports
import { Image } from "@Components/Basic";
import {
    Container,
    IconsDiv,
    IconButton,
} from "./styled";
import Link from "next/link";
import { ILandingMobileHeader } from "./LandingMobileHeader";
import { AppleStoreMin, GooglePlayMin } from "@Icons";
/* import { Anniversary20th } from "../LandingHeader/styled";
import { IStore } from "@Interfaces"; */
// #endregion Local Imports
export const LandingMobileHeader: React.FunctionComponent<ILandingMobileHeader.IProps> = ({
    showLogoText,
}): JSX.Element => {
    const [isIOS, setIsIOS] = React.useState(false);
   /*  const { topComponent } = useSelector(({ home }: IStore) => home); */
    React.useEffect(() => {
        if (/iphone|ipad|ipod|mac os x/i.test(navigator.userAgent)) {
            setIsIOS(true);
        }
        else {
            setIsIOS(false);
        }
    })

    const src = `icons/siberalem-${showLogoText ? "logo" : "hearts"}-colored.svg`;
    const alt = showLogoText ? "siberalem logo full" : "siberalem logo hearts";
    const className = showLogoText ? "with-text" : "without-text";

    return (
        <Container>
            <Image src={src} alt={alt} className={className} />

            {/*  {topComponent.match("card") && (
                <Anniversary20th>
                    <img
                        data-cy=""
                        srcSet="/images/Badge.png, /images/Badge@2.png 2x"
                        src="/images/Badge@2.png"
                        alt="Siberalem 20 Yaşında!"
                    />
                </Anniversary20th>
            )} */}
            <IconsDiv>
                {
                    isIOS ? (
                        <IconButton className="apple-store">
                            <Link href="//itunes.apple.com/tr/app/siberalem/id649741474?mt=8">
                                <a target="_blank" className="text-center">
                                    <AppleStoreMin width="16px" height="16px" />
                                </a>
                            </Link>
                        </IconButton>
                    ):
                    (
                        <IconButton className="google-play">
                            <Link href="//play.google.com/store/apps/details?id=com.workbench.siberalem&hl=tr">
                                <a target="_blank" className="text-center">
                                    <GooglePlayMin width="16px" height="16px" />
                                </a>
                            </Link>
                        </IconButton>
                    )
                }
            </IconsDiv>
        </Container>
    );
};
