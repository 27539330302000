// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Anchor } from "@Components/Basic";
import { Modal, LandingMobileHeader } from "@Components";
import { useScreenSize, theme } from "@Definitions";
import { CloseIcon } from "@Icons";
import { IFormBox } from "./FormBox";
import { Container, HeaderSlug, BoxHeader, ChildrenContainer } from "./styled";
// #endregion Local Imports

export const FormBox: React.FunctionComponent<IFormBox.IProps> = ({
    title,
    children,
    onCloseClick,
    isLarge,
    isDismissible = true,
    customModalCloseIcon,
    onHandleSubmit,
    customChildrenStyle,
    customContainerStyle,
    customBoxHeaderStyle,
    renderTitle,
    cy,
}): JSX.Element => {
    const screenSize = useScreenSize();
    const isMobile = !!screenSize.match(/xsmall/i);

    return isMobile ? (
        <Modal customModalCloseIcon={customModalCloseIcon} isDismissible onDismiss={onCloseClick}>
            <Container isLarge={isLarge} isMobile={isMobile} customStyle={customContainerStyle}>

                <ChildrenContainer customStyle={customChildrenStyle}>
                    <form onSubmit={onHandleSubmit} data-cy={cy}>
                        {renderTitle ? renderTitle() : <HeaderSlug>{title}</HeaderSlug>}

                        {children}
                    </form>
                </ChildrenContainer>

                <input type="submit" className="d-none" />
            </Container>
        </Modal>
    ) : (
        <Container isLarge={isLarge} isMobile={isMobile} customStyle={customContainerStyle}>
            <BoxHeader customStyle={customBoxHeaderStyle}>
                {renderTitle ? (
                    renderTitle()
                ) : (
                    <>
                        <div />
                        {title}
                    </>
                )}

                {isDismissible && !isMobile && (
                    <Anchor onClick={onCloseClick}>
                        <CloseIcon width="20px" height="24px" color={theme.colors.white}/>
                    </Anchor>
                )}
            </BoxHeader>

            <form onSubmit={onHandleSubmit} data-cy={cy}>
                <ChildrenContainer customStyle={customChildrenStyle}>{children}</ChildrenContainer>
                <input type="submit" className="d-none" />
            </form>
        </Container>
    );
};
