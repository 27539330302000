// #region Global Imports
import React, { FC, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Link from "next/link";
import Router from "next/router";
import * as Scroll from "react-scroll";
// #endregion Global Imports

// #region Local Imports
import { Container, Row, Col } from "@Styled/Grid";
import { IStore } from "@Interfaces";
import { AppleIcon, GooglePlay, SiberalemLogo } from "@Icons";
import { socials, backlinks, useFooterLinks } from "./index.data";
import { IFooter } from "./Footer";
import {
    FooterWrapper,
    LogoContainer,
    SocialLogo,
    SocialLogoContainer,
    MiddleHeader,
    MiddleContent,
    MiddleLeft,
    MiddleLeftContent,
    MiddleRight,
    MiddleRightAddres,
    FooterLinkWrapper,
    FooterLink,
    LinkItem,
    SiteLinkWrapper,
    SiteLinks,
    StoreAppleLogo,
    StoreGoogleLogo,
} from "./styled";
// #endregion Local Imports

export const Footer: FC<IFooter.IProps> = ({ t }) => {
    const [didMount, setDidMount] = useState(false);

    const profile = useSelector((state: IStore) => state.profile.self);

    const links = useFooterLinks(t, profile);

    const handleBrandClick = () => {
        if (!didMount) return;

        if (profile && !Router.route.includes("dashboard")) {
            Router.push("/dashboard");
            return;
        }

        Scroll.animateScroll.scrollToTop();
    };

    useEffect(() => {
        setDidMount(true);
    }, []);

    return (
        <FooterWrapper>
            <Container>
                <Row>
                    <Col className="col-md-12 col-lg-4">
                        <LogoContainer>
                            <SiberalemLogo
                                className="mb-2 brand"
                                viewBox="0 0 302 65"
                                width="236"
                                height="51"
                                onClick={handleBrandClick}
                            />

                            <SocialLogoContainer>
                                {socials.map(({ href, name, nofollow }) => (
                                    <Link key={`${name}-link`} href={href}>
                                        <a
                                            target="_blank"
                                            {...(nofollow ? { rel: "nofollow" } : {})}
                                            >
                                            <SocialLogo className={`icon ${name}-icon`} />
                                        </a>
                                    </Link>
                                ))}

                                <StoreAppleLogo>
                                    <a
                                        href="//itunes.apple.com/tr/app/siberalem/id649741474?mt=8"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <AppleIcon />
                                    </a>
                                </StoreAppleLogo>

                                <StoreGoogleLogo>
                                    <a
                                        href="//play.google.com/store/apps/details?id=com.workbench.siberalem&hl=tr"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <GooglePlay />
                                    </a>
                                </StoreGoogleLogo>
                            </SocialLogoContainer>
                        </LogoContainer>
                    </Col>

                    <Col className="col-md-12 col-lg-8">
                        <MiddleContent>
                            <MiddleLeft>
                                <MiddleHeader>
                                    {t("common:footer.Siberalem bir Workbench")}
                                </MiddleHeader>

                                <MiddleLeftContent>
                                    <span>
                                        {t("common:footer.Yazılı izin olmadan kullanılamaz")}
                                    </span>

                                    <span>
                                        {t("common:footer.Siberalem üyelerinin sergilediği")}
                                    </span>
                                </MiddleLeftContent>
                            </MiddleLeft>

                            <MiddleRight>
                                <MiddleHeader>
                                    {t("common:footer.Sosyal Bilgi İletişim")}
                                </MiddleHeader>

                                <MiddleRightAddres>
                                    <span>{t("common:footer.adress.district")}</span>

                                    <span>{t("common:footer.adress.province")}</span>

                                    <span>
                                        <a href="mailto:bilgi@siberalem.com">
                                            {t("common:footer.adress.email")}
                                        </a>
                                    </span>

                                    <span>
                                        <a href="tel:+902122529449">
                                            {t("common:footer.adress.number")}
                                        </a>
                                    </span>
                                </MiddleRightAddres>
                            </MiddleRight>
                        </MiddleContent>
                    </Col>
                </Row>

                <FooterLinkWrapper>
                    <FooterLink>
                        {links.map(({ href, name, internal, nofollow }) => (
                            <Link key={`${name}-link`} href={href}>
                                <a
                                    target={internal ? "" : "_blank"}
                                    {...(nofollow ? { rel: "nofollow" } : {})}
                                    >
                                    <LinkItem>{name}</LinkItem>
                                </a>
                            </Link>
                        ))}
                    </FooterLink>
                </FooterLinkWrapper>

                <SiteLinkWrapper>
                    <SiteLinks>
                        {backlinks.map(backlink => (
                            <Link key={`${backlink}-backlink`} href={`//${backlink}`}>
                                <a target="_blank" rel="nofollow" >
                                    <LinkItem>{backlink}</LinkItem>
                                </a>
                            </Link>
                        ))}
                    </SiteLinks>
                </SiteLinkWrapper>
            </Container>
        </FooterWrapper>
    );
};
