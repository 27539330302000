// #region Global Imports
import styled, { css } from "styled-components";
// #endregion GLobal Imports

export const DFlex = styled.div`
    display: flex;
    align-items: center;
`;

export const Anniversary20th = styled.div`
    position: absolute;
    right: 16px;
    top: 100%;

    @media (min-width: ${({ theme }) => theme.minWidth.small}) {
        top: -8px;
    }

    @media (min-width: ${({ theme }) => theme.minWidth.medium}) {
        position: relative;
        top: -8px;
        right: unset;
    }

    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        flex: 1;
        left: 32px;
    }
`;

export const Container = styled.div<{ isSticky: boolean }>`
    width: 100%;
    height: 121px;
    position: relative;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    ${({ isSticky }) =>
        isSticky &&
        css`
            ${Anniversary20th} {
                display: none;
            }

            background: rgba(42, 42, 42, 0.85);
            box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.3);
        `};

    .hidden {
        display: none;
    }
`;

export const ContentContainer = styled(DFlex)`
    width: 100%;
    height: 100%;
    justify-content: center;
`;

export const Content = styled(DFlex)`
    height: 100%;
    justify-content: space-between;

    @media (min-width: ${({ theme }) => theme.minWidth.xLarge}) {
        width: 1120px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 920px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 700px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        justify-content: center;
    }
`;

export const LogoDiv = styled.div`
    width: 300px;
    height: 62px;
    position: relative;
    cursor: pointer;

    img {
        height: 100%;
    }
`;

export const ButtonsDiv = styled(DFlex)`
    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        display: none;
    }
`;

export const IconsDiv = styled(DFlex)`
    margin-left: 10px;
`;

export const IconButton = styled.div`
    display: grid;
    place-items: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: black;
    margin-right: 3px;

    &.apple-store {
        padding-bottom: 2px;
    }

    &.google-play {
        padding-left: 1px;
    }
`;

export const StoreIcon = styled.i`
    line-height: 1.6;
    font-size: 18px;
    color: white;
`;

StoreIcon.displayName = "StoreIcon";
