// #region Local Imports
import Nookies from "nookies";
import { AmplitudeClient } from "amplitude-js";
import { getEnvSafely } from "@Definitions";
import { CampaignModel } from "@Interfaces";
// #endregion Local Imports

// #region Interface Imports
// #endregion Interface Imports

let amplitude: AmplitudeClient;
let mixPanel: Mixpanel;

export const Analytics = {
    init: () => {
        // eslint-disable-next-line global-require
        amplitude = require("amplitude-js");
        amplitude?.init(getEnvSafely("AMPLITUDE_API_KEY"), undefined, {
            includeReferrer: true,
            includeUtm: true,
        });

        // eslint-disable-next-line global-require
        mixPanel = require("mixpanel-browser");
        console.log(mixPanel);
        mixPanel?.init(getEnvSafely("MIXPANEL_API_KEY"));

        const variantProperty = {
            sa_variant: "2020-react-web-app",
        };

        amplitude?.setUserProperties({ ...variantProperty });

        mixPanel?.register({
            ...variantProperty,
        });
    },
    event: (name: string, data?: object, isSample?: boolean) => {
        if (isSample) {
            const { account } = Nookies.get();
            if (account) {
                const parsedAccount = JSON.parse(account);
                const userId = parsedAccount.user.UserId;
                if (userId) {
                    if (userId % 100 !== 0) {
                        return;
                    }
                }
            }
        }

        if (data) {
            const extendDataWithVariant = {
                ...data,
                sa_variant: "2020-react-web-app",
            };
            amplitude?.logEvent(name, extendDataWithVariant);
            mixPanel?.track(name, extendDataWithVariant);
        } else {
            amplitude?.logEvent(name);
            mixPanel?.track(name);
        }
    },
    setUserId: (userId: string) => {
        if (window.gtag) {
            window.gtag('event', 'login', {
                event_category: 'Login',
                event_label: 'UserId',
                user_id: userId
            });
        }
    },
    setUserProperties: (data: object) => {
        amplitude?.setUserProperties(data);

        // replace registerSuperProperties to register
        mixPanel?.register({
            ...data,
        });
    },
    accountRegister: (userId: string) => {
        if (window.gtag) {
            window.gtag('event', 'sign_up', {
                event_category: 'Üyelik',
                event_label: 'UserId',
                user_id: userId
            });
        }
        if (window.fbq) {
            window.fbq('track', 'CompleteRegistration', {
                user_id: userId
            });
        }
        if (window.ttq) {
            window.ttq.track('CompleteRegistration', {
                user_id: userId
            });
        }
    },
    reset: () => {
        amplitude?.setUserId(null);
        mixPanel?.reset();
    },
    revenue: (selectedCampaign: CampaignModel.Campaign, type: string, userId?: string) => {
        const { Id, DiscountedPrice, InternalName, Name, Discount } = selectedCampaign;
        const formattedPrice = typeof DiscountedPrice === "string" ?
            Number((DiscountedPrice as string).replaceAll(",", "")) :
            DiscountedPrice;
        const formattedDate = new Date().toISOString().slice(0, 10).replace(/-/g, '');
        const transactionId = `T${userId}-${Id}-${formattedDate}`;

        if (window.gtag) {
            window.gtag("event", "purchase", {
                transaction_id: transactionId,
                value: formattedPrice,
                currency: 'TRY',
                coupon: Name,
                items: [{
                    item_id: Id,
                    item_name: InternalName,
                    affiliation: "Siberalem",
                    coupon: Name,
                    discount: Discount,
                    item_brand: "Siberalem",
                    item_category: type,
                    item_list_id: Id,
                    item_list_name: Name,
                    item_variant: type,
                    price: formattedPrice,
                    quantity: 1
                }]
            });
        }
        if (window.fbq) {
            window.fbq('track', 'Purchase', {
                value: formattedPrice,
                currency: 'TRY',
                coupon: Name,
                contents: [{
                    id: Id,
                    name: InternalName,
                    brand: 'Siberalem',
                    category: type,
                    variant: type,
                    price: formattedPrice,
                    quantity: 1
                }]
            });
        }
        if(window.ttq){
            window.ttq.track('CompletePayment', {
                value: formattedPrice,
                currency: 'TRY',
                coupon: Name,
                contents: [{
                    content_id: Id,
                    content_name: InternalName,
                    brand: 'Siberalem',
                    content_category: type,
                    content_variant: type,
                    price: formattedPrice,
                    quantity: 1
                }]
            });
        }  
    },
    pageEvent: () => {
        if (window.gtag) {
            window.gtag('event', 'page_view', {
                page_path: window.location.pathname,
                page_title: document.title
            });
        }
    },
};
